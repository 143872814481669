import { createVuetify } from 'vuetify'
import { it } from 'vuetify/locale'
import DayJsAdapter from '@date-io/dayjs'
import themes from '~/theme'
import { VDateInput } from 'vuetify/labs/VDateInput'
import { VTimePicker } from 'vuetify/labs/VTimePicker'

export default defineNuxtPlugin((app) => {
  // @ts-ignore
  const {$i18n}: { $i18n: any } = app

  const formFieldConfig = {
    variant: 'outlined',
    hideDetails: 'auto',
    density: 'compact'
  }

  const vuetify = createVuetify({
    locale: {
      locale: 'it',
      messages: {it}
    },
    theme: {
      themes,
      defaultTheme: 'mobile'
    },
    display: {
      mobileBreakpoint: 'md'
    },
    date: {
      adapter: DayJsAdapter,
    },
    components: {
      VDateInput,
      VTimePicker
    },
    defaults: {
      VAlert: {
        variant: 'tonal'
      },
      VAppBar: {
        VBtn: {
          color: 'white'
        }
      },
      VBtn: {
        variant: 'flat',
        color: 'primary',
        class: 'font-weight-bold'
      },
      VTooltip: {
        openDelay: 150
      },
      VCard: {
        class: 'rounded-lg',
        elevation: 0,
        VCardTitle: {
          class: ''
        },
        VCardActions: {
          class: 'd-flex justify-end pa-4',
          VBtn: {
            variant: 'flat'
          }
        }
      },
      VList: {
        VListItem: {
          class: 'list-item-border'
        }
      },
      VWindowItem: {
        transition: 'slide-x-transition'
      },
      VForm: {
        VImg: {
          class: 'bordered rounded'
        }
      },
      VTextField: {
        ...formFieldConfig
      },
      VSelect: {
        ...formFieldConfig,
        clearIcon: 'mdi-close-circle-outline'
      },
      VAutocomplete: {
        ...formFieldConfig,
        clearIcon: 'mdi-close-circle-outline'
      },
      VTextarea: {
        ...formFieldConfig
      },
      VFileInput: {
        ...formFieldConfig
      },
      VDateInput: {
        ...formFieldConfig,
        prependIcon: '',
        prependInnerIcon: '$calendar',
        placeholder: 'dd/mm/yyyy'
      },
      VSwitch: {
        inset: true,
        density: 'compact',
        hideDetails: 'auto'
      },
      VDataTable: {
        density: 'compact',
        fixedHeader: true,
        loadingText: $i18n.t('lbl.loading'),
        itemsPerPageText: $i18n.t('lbl.items_per_page'),
        itemsPerPageOptions: [5, 10, 20, 50],
        noDataText: $i18n.t('lbl.no_data'),
        pageText: $i18n.t('lbl.page', {start: '{0}', end: '{1}', tot: '{2}'}),
        class: 'rounded-lg elevation-1'
      },
      VDataTableServer: {
        density: 'compact',
        fixedHeader: true,
        loadingText: $i18n.t('lbl.loading'),
        itemsPerPageText: $i18n.t('lbl.items_per_page'),
        itemsPerPageOptions: [5, 10, 20, 50],
        noDataText: $i18n.t('lbl.no_data'),
        pageText: $i18n.t('lbl.page', {start: '{0}', end: '{1}', tot: '{2}'}),
        class: 'rounded-lg'
      },
      VExpansionPanels: {
        VExpansionPanel: {
          bgColor: 'transparent',
          elevation: 0
        }
      },
      VFooter: {
        class: 'bg-background'
      }
    }
  })
  app.vueApp.use(vuetify)
})
