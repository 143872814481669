// https://pinia.vuejs.org/core-concepts/#option-stores
import { defineStore } from 'pinia'
import { LANGUAGES } from '~/consts/languages'

export interface State {
  languageCode: string
}

export const useLanguageStore = defineStore('language', {
  persist: true,
  state: (): State => ({
    languageCode: 'it'
  }),
  actions: {
    setLanguageCode(language: string) {
      this.languageCode = language
      const {$i18n} = useNuxtApp()
      $i18n.setLocale(this.languageCode)
    }
  },
  getters: {
    getLanguage: (state) => {
      return LANGUAGES.find((l: Language) => l.value === state.languageCode)
    },
    getLanguageCode: (state) => {
      return state.languageCode
    }
  }
})
