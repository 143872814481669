import revive_payload_client_4sVQNw7RlN from "/workspace/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/workspace/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/workspace/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/workspace/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/workspace/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_UciE0i6zes from "/workspace/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/workspace/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/workspace/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/workspace/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_client_Eorgdr12jA from "/workspace/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_client_RZVNejKJBe from "/workspace/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import plugin_Jozdw60ZsE from "/workspace/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/workspace/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/workspace/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_AOuQ1DYzjk from "/workspace/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_AUP22rrBAc from "/workspace/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import _01_sentry_gFYQZUiw9w from "/workspace/plugins/01_sentry.ts";
import activate_lodgings_u1pYQBn98w from "/workspace/plugins/activate-lodgings.ts";
import chartjs_1wC9lAM0Tp from "/workspace/plugins/chartjs.ts";
import config_ofetch_ycgFkn7502 from "/workspace/plugins/config-ofetch.ts";
import lightbox_QOw7fFAs55 from "/workspace/plugins/lightbox.ts";
import onesignal_rdSNJRfglp from "/workspace/plugins/onesignal.ts";
import pull_to_refresh_DAazpZYdjl from "/workspace/plugins/pull-to-refresh.ts";
import pusher_T6fDwDIeLY from "/workspace/plugins/pusher.ts";
import set_language_ylIVOhZ4BW from "/workspace/plugins/set-language.ts";
import set_locale_yHhCY3lxCG from "/workspace/plugins/set-locale.ts";
import side_panel_PNaO8tybrs from "/workspace/plugins/side-panel.ts";
import toastification_xLadjaQwvu from "/workspace/plugins/toastification.ts";
import vuetify_use_dialog_ViwAdh2esy from "/workspace/plugins/vuetify-use-dialog.ts";
import vuetify_7h9QAQEssH from "/workspace/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_client_Eorgdr12jA,
  plugin_client_RZVNejKJBe,
  plugin_Jozdw60ZsE,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_AOuQ1DYzjk,
  plugin_AUP22rrBAc,
  _01_sentry_gFYQZUiw9w,
  activate_lodgings_u1pYQBn98w,
  chartjs_1wC9lAM0Tp,
  config_ofetch_ycgFkn7502,
  lightbox_QOw7fFAs55,
  onesignal_rdSNJRfglp,
  pull_to_refresh_DAazpZYdjl,
  pusher_T6fDwDIeLY,
  set_language_ylIVOhZ4BW,
  set_locale_yHhCY3lxCG,
  side_panel_PNaO8tybrs,
  toastification_xLadjaQwvu,
  vuetify_use_dialog_ViwAdh2esy,
  vuetify_7h9QAQEssH
]