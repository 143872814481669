export const LANGUAGES = [
  {
    value: 'en',
    label: 'English',
    flag_path: '/languages/en.png'
  },
  {
    value: 'it',
    label: 'Italiano',
    flag_path: '/languages/it.png'
  },
  {
    value: 'ro',
    label: 'Română',
    flag_path: '/languages/ro.png'
  },
  {
    value: 'ru',
    label: 'Русский',
    flag_path: '/languages/ru.png'
  },
  {
    value: 'uk',
    label: 'Українська',
    flag_path: '/languages/uk.png'
  }
]