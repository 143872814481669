/*
Ad ogni avvio app, imposto la lingua in base alla lingua dell'utente
*/
import { useLanguageStore } from '~/store/language'

export default defineNuxtPlugin(async (app) => {
  const { status, data: user } = useAuth()
  const store = useLanguageStore()
  // @ts-ignore
  const {$i18n}: { $i18n: any } = app

  if (status.value === 'authenticated' && !!user.value) {
    store.setLanguageCode(user.value.language)
    $i18n.locale.value = user.value.language
  }
})